.dashboard-user-welcome {
    background: #E1EFFF;
    color: #555B8E;
    min-height: 110px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    padding: 0 2em;
    margin-bottom: 1em;
    background-image: url(https://lahaus.imgix.net/static/plus/investors/assets/playing-boy2.png);
    background-size: 120px;
    background-repeat: no-repeat;
    background-position: 90% -10%;

    @media screen and (max-width: 600px) {

        h3 {

            span {
                display: block; } } } }
