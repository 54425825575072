@import url("./assets/fonts/Silka/stylesheet.css");
@import './hagrid';

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";



body {
  font-family: 'silkaregular';
  margin: 0;
  padding: 0;
  color: #555B8E;
  font-size: 14px; }


h3 {
	font-size: 30px;
	font-weight: 600;
	margin: 0; }

h4 {
	font-size: 22px;
	font-weight: 600; }

b {
	font-weight: bold; }




.gap-20 {
	column-gap: 20px; }



.align-items-center {
	align-items: center; }

.align-items-end {
	align-items: end; }

.text-align-left,
.text-left {
	text-align: left; }

.text-align-center,
.text-center {
	text-align: center; }

.text-align-right,
.text-right {
	text-align: right; }

.flex {
	display: flex; }

.items-start {
	align-items: flex-start; }

.flex-direction-column {
	flex-direction: column; }

.bold-600 {
	font-weight: 600; }

.text-14 {
	font-size: 14px; }

.text-16 {
	font-size: 16px; }

.text-18 {
	font-size: 18px; }

.text-33 {
	font-size: 33px; }

.mt-2em {
	margin-top: 2em;
	margin-bottom: 2em; }


.mr-10px {
	margin-right: 10px; }
.mr-20px {
	margin-right: 20px; }
.mr-30px {
	margin-right: 30px; }

.ml-10px {
	margin-left: 10px; }
.ml-20px {
	margin-left: 20px; }
.ml-30px {
	margin-left: 30px; }

.mt-10px {
	margin-top: 10px; }
.mt-20px {
	margin-top: 20px; }
.mt-30px {
	margin-top: 30px; }

.mb-0 {
	margin-bottom: 0px; }
.mb-10px {
	margin-bottom: 10px; }
.mb-20px {
	margin-bottom: 20px; }
.mb-30px {
	margin-bottom: 30px; }



.error {
	color: #f96f6c;
	font-size: 14px!important; }

.white-card {
	background-color: #fff;
	border-radius: 25px;
	padding: 2em;
	//margin-bottom: 1em
	border: 1px solid #EEE5FF;
	height: 100%;
	box-sizing: border-box;

	@media screen and (max-width: 600px) {
		padding: 2em 1em; } }


.btn {
	background-color: #3948FF;
	color: #fff;
	border-radius: 5px;
	font-size: 16px;
	padding: 16px 20px 16px 20px;
	display: inline-block;
	cursor: pointer;
	border: none;
	transition: .3s all;
	text-decoration: none;
	font-weight: 600;

	&:disabled,
	&[disabled] {
		opacity: .5;
		cursor: not-allowed; }

	&.btn-block {
		width: 100%;
		display: block;
		box-sizing: border-box;
		text-align: center; }

	&.btn-green {
		background-color: #3ECFAF; }

	&.btn-sm {
		padding: 10px 20px 10px 20px;
		font-size: 14px; } }



.input-radio {
	display: inline-block;
	margin-right: 1.5em;
	margin-bottom: 1.5em;

	label {
		background-color: #F9FAFF;
		border: 1px solid #CDB7F8;
		padding: 16px 20px 16px 20px;
		border-radius: 35px;
		display: inline-block;
		cursor: pointer;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		transition: .3s all;

		&:hover {
			background-color: rgba(#EEE5FF, .5); } }

	input {
		display: none;

		&:checked+label {
			background-color: #EEE5FF; } } }


.field {
	padding: .5em 0;

	.appearance-none {
		position: relative;
		border: 0;
		border-bottom: 1px solid #ececec;
		display: block;
		padding: 1em 0;
		font-size: 16px;
		color: #242424;
		width: 100%;
		background-color: transparent;
		outline: none; }

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		background-color: transparent;
		-webkit-box-shadow: none; } }



.text-lh-plus-french-sky-blue {
	color: #729cff; }


.slick-arrow {
	display: none!important; }

.slick-dots {
	button {
		&:before {
			color: #3948FF!important;
			font-size: 12px!important; } } }


@media screen and (max-width: 600px) {
	.box-m-bottom {
		margin-bottom: 2em; }

	.box-p-bottom {
		padding-bottom: 2em; } }
