.page-dashboard-view {
    background-color: #F9FAFF;
    min-height: 100vh;
 }    //padding-left: 175px

.main-dashboard-view {
    max-width: 1400px;
    padding: 3em 20px;

    @media screen and (max-width: 600px) {
        padding: 3em 10px; } }



.group-components {
    margin-bottom: 2em; }

