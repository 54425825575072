.__inner-section {
    max-width: 525px;
    margin: auto; }

.__icons {
    .__icon {
        figure {
            border: 2px solid #71B5FF;
            width: 96px;
            height: 96px;
            border-radius: 96px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto; }

        span {
            display: block;
            text-align: center;
            padding: 1em 0; } }

    .__arrow {
        top: -25px;
        position: relative; } }

.slider-mobile {
    width: 300px;
    margin: auto;
    display: none;
    padding: 2em 0;

    @media screen and (max-width: 600px) {
        display: block; } }

.__group-icons {
    display: flex;
    align-items: center;
    padding: 3em 0;

    @media screen and (max-width: 600px) {
        display: none; } }






