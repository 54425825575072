.dashboard-concierge-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }

.header-concierge {
    figure {
        margin: 0;
        margin-right: 1em;

        img {
            border-radius: 100px; } } }

.skills-area {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            padding: 2px 1em;
            margin: 0 10px 10px 0;
            background-color: #EEE5FF;
            color: #283BE7;
            font-size: 13px;
            border-radius: 5px; } } }

