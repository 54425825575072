
.header-hand-picked {
    background-color: #EBEDFF;
    border-radius: 10px;
    padding: 1em;
    margin-bottom: 1em;

    figure {
        margin: 0;
        text-align: center;
        display: inline-block;
 } }        //padding-right: 15px


.hand-picked-gallery {
    display: flex;
    column-gap: 20px;

    .main-card {
        border: 1px solid #CCD5D4;
        border-radius: 13px;
        //width: 70%

        figure.preview-photos {
            height: 270px;
            border-radius: 13px 13px 0 0; }

        .__info {
            ul {
                display: flex;
                list-style: none;
                margin: 0;
                padding: 0;
                flex-wrap: wrap;
                padding: 1em 0;

                li {
                    width: 50%;
                    position: relative;
                    display: flex;
                    padding: .5em 0;

                    figure {
                        margin: 0 10px;
                        //position: absolute
                        //left: 0
                        //top: 0

                        img {
                            width: 21px;
                            height: auto; } } } } } }

    .thumbsnail-cards {
        //width: 30%
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .thumbsnail-cards__item {
            height: 31%;
            box-sizing: border-box;
            border: 3px solid #fff;
            cursor: pointer;

            &.__active {

                .preview-photos {
                    border: 3px solid #71B5FF; } } }


        .preview-photos {
            height: 100%;
            box-sizing: border-box; }

        .__counter {
            width: 30px;
            height: 30px;
            border-radius: 30px;
            border: 1px solid #3948FF;
            text-align: center;
            line-height: 30px;
            font-size: 16px;
            color: #3948FF;
            display: none; }

        @media screen and (max-width: 600px) {
            flex-direction: row;
            padding: 2em 2em 1em;

            .preview-photos {
                display: none; }

            .__counter {
                display: block; } } }


    figure.preview-photos {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        color: #fff;
        margin: 0;
        padding: 1em;
        border-radius: 13px;
        overflow: hidden;

        b,
        span {
            display: block; }

        b {
            font-size: 18px; } }

    .__price-n-options {
        padding: 1em;
        text-align: center;

        b,
        span {
            display: block; }

        b {
            font-size: 18px; } } }

