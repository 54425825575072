
.header-plusvalia {
    background-color: #EBEDFF;
    border-radius: 10px;
    padding: 1em;
    margin-bottom: 1em;

    figure {
        margin: 0;
        text-align: center;
        display: inline-block;
        padding-right: 15px; } }

.list-plusvalia {
    max-height: 480px;
    overflow-y: auto;
    .list-plusvalia__item {
        background-color: #EBFFFE;
        border-radius: 10px;
        color: #555B8E;
        padding: .7em 1em .7em 50px;
        margin-bottom: 1em;
        position: relative;

        .__counter {
            position: absolute;
            font-size: 18px;
            background-color: #fff;
            color: #555B8E;
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 20px;
            font-weight: bold;
            text-align: center;
            left: 15px;
            top: 18px; }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                padding: 5px 0;
                display: flex;
                justify-content: space-between;
                font-size: 16px;

                &.li-dotted {
                    border-bottom: 1px dashed #555B8E; }

                .__icon {
                    display: inline-block;
                    width: 15px;
                    height: 18px;
                    background-image: url(https://lahaus.imgix.net/static/plus/investors/assets/arrow-up.png);
                    background-size: 12px;
                    background-repeat: no-repeat;
                    background-position: center;
                    font-style: normal;

                    &.__icon-down {
                        background-image: url(https://lahaus.imgix.net/static/plus/investors/assets/arrow-down.png); } } } } } }
